import React, { useState, useEffect, useRef } from 'react'
import { useUser } from 'util/UserContext'
import { Link, useNavigate } from 'react-router-dom'
import EntityActions from 'components/Entities/EntityActions'
import api from 'config/axiosConfig'
import {
    Menu,
    MenuItem,
    IconButton,
    Modal,
    ModalDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/joy'
import { ReactComponent as MoreIcon } from 'assets/button-card-actions.svg'
import { ReactComponent as EditIcon } from 'assets/button-edit.svg'
import { ReactComponent as ReportIcon } from 'assets/button-report.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon-delete.svg'

import { Solution } from 'util/constants'

interface Props {
    solution: Solution
    showMeta?: boolean
    truncateDescription: boolean
    isSolutionProfilePage?: boolean
    showActions?: boolean
}

const SolutionCard = ({
    solution,
    showMeta = true,
    truncateDescription = true,
    isSolutionProfilePage = false,
}: Props) => {
    const { currentUser } = useUser();
    const navigate = useNavigate()
    const [isExpanded, setIsExpanded] = useState(false)
    const solutionHref = `/solution/${encodeURIComponent(solution.id)}`
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const moreIconRef = useRef<HTMLDivElement>(null)

    const truncateText = (text: string, length: number) => {
        if (!text || text.length <= length) return text
        return text.substring(0, length) + '...'
    }

    const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const element = event.target as HTMLElement
        // Prevent navigation when clicking on the reference link
        if (element.tagName.toLowerCase() === 'a') return
        navigate(solutionHref)
    }

    const handleDescriptionClick = (event: React.MouseEvent) => {
        if (!truncateDescription) return
        event.preventDefault()
        if (isSolutionProfilePage) {
            setIsExpanded(!isExpanded)
        } else {
            return
        }
    }

    const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null) // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget) // Open the menu
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleEdit = () => {
        navigate(`/solution/${solution.id}?edit=true`)
    }

    const handleDelete = async () => {
        try {
            await api.delete(`/api/solutions/id/${solution.id}`)
            setIsDeleteDialogOpen(false)
            if (isSolutionProfilePage) {
                navigate('/')
            } else {
                window.location.reload()
            }
        } catch (error) {
            console.error('Error deleting Solution:', error)
        }
    }

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true)
    }

    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false)
    }

    // Close the menu if clicked outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const element = event.target as HTMLElement
            if (anchorEl && !moreIconRef.current?.contains(element)) {
                handleMenuClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [anchorEl])

    return (
        <div className="container">
            <div className="card">
                <div className="entity-actions-container">
                    <EntityActions
                        entity={solution}
                        entityType="solution"
                    />
                </div>
                <div className="card-content" onClick={handleCardClick}>
                    <div className="card-header">
                        <Link
                            to={`/solution/${solution.id}`}
                            className="card-title"
                        >
                            {solution.name}
                        </Link>
                    </div>
                    <div
                        className="card-description"
                        onClick={handleDescriptionClick}
                    >
                        <p>
                            {truncateDescription && !isExpanded
                                ? truncateText(solution.description || '', 512)
                                : solution.description}
                        </p>
                    </div>
                    {solution.reference_link && (
                        <a
                            href={solution.reference_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="solution-card-link"
                        >
                            {truncateText(solution.reference_link || '', 64)}
                        </a>
                    )}
                </div>
                {showMeta && (
                    <div className="card-meta">
                        <Link
                            to={`/user/${solution.username}`}
                            className="username"
                        >
                            {solution.username}
                        </Link>
                        <span>
                            {solution.updated_ts
                                ? new Date(solution.updated_ts).toLocaleString()
                                : 'No update date'}
                        </span>
                        <div className="icon-more">
                            <IconButton onClick={handleMenuToggle}>
                                <MoreIcon />
                            </IconButton>
                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            ref={moreIconRef}
                        >
                            <div className="icon-edit">
                                {`${currentUser?.id}` ===
                                    `${solution.user_id}` && (
                                    <>
                                        <MenuItem onClick={handleEdit}>
                                            <button>
                                                <EditIcon />
                                            </button>
                                        </MenuItem>
                                        <div className="icon-delete">
                                            <MenuItem
                                                onClick={openDeleteDialog}
                                            >
                                                <button>
                                                    <DeleteIcon />{' '}
                                                </button>
                                            </MenuItem>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="icon-report">
                                <MenuItem onClick={handleMenuClose}>
                                    <button>
                                        {' '}
                                        <ReportIcon />
                                    </button>
                                </MenuItem>
                            </div>
                        </Menu>
                    </div>
                )}
            </div>
            {/* Confirmation Dialog */}
            <Modal
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <ModalDialog>
                    <DialogTitle id="delete-dialog-title">
                        {'Delete Solution'}
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this Impact?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteDialog} color="primary">
                            No
                        </Button>
                        <Button
                            onClick={handleDelete}
                            color="primary"
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </div>
    )
}

export default SolutionCard
