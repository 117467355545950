import React from 'react'
import { Box } from '@mui/joy'

interface EnemyThumbnailProps {
    imageUrl: string | null
}

const EnemyThumbnail: React.FC<EnemyThumbnailProps> = ({ imageUrl }) => {
    if (!imageUrl) {
        return null // or a placeholder image
    }

    return (
        <Box
            component="img"
            src={imageUrl}
            alt="Enemy Thumbnail"
            className="rounded-md object-cover max-h-[40vh] md:max-h-40"
        />
    )
}

export default EnemyThumbnail
