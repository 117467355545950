import React, { useState, RefObject } from 'react'
import { useUser } from 'util/UserContext'
import {
    Button,
    Modal,
    ModalDialog,
    Typography,
    Chip,
    Textarea,
    Box,
    Stack,
    MenuItem,
} from '@mui/joy'
import api from 'config/axiosConfig'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'

interface Props {
    entityId: string
    entityType: string
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    modalRef: RefObject<HTMLDivElement>
    onReportClick: () => void
}

const REPORT_REASONS = [
    'Harassment',
    'Misinformation',
    'Threatening violence',
    'Hate speech',
    'Sharing personal information',
    'Impersonation',
    'Self-harm or suicide',
    'Spam',
]

const Report = ({
    entityId,
    entityType,
    isOpen,
    setIsOpen,
    modalRef,
    onReportClick
}: Props) => {
    const { currentUser, setShowAuthModal } = useUser();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false)
    const [selectedReason, setSelectedReason] = useState<string | null>(null)
    const [description, setDescription] = useState('')

    const handleReportClick = () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else {
            setIsOpen(true)
        }
    }

    const handleReasonSelect = (reason: string) => {
        setSelectedReason(reason === selectedReason ? null : reason)
    }

    const handleSubmit = async () => {
        if (!selectedReason) return

        try {
            await api.post('/api/reports', {
                entityType,
                entityId,
                reason: selectedReason,
                description: description.trim() || null,
            })
            setIsOpen(false)
            setIsConfirmationModalOpen(true)
            // Reset form
            setSelectedReason(null)
            setDescription('')
        } catch (error) {
            console.error('Error submitting report:', error)
            // Handle error (e.g., show error message to user)
        }
    }

    const handleModalContentClick = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <>
            <MenuItem onClick={handleReportClick}>
                <FlagOutlinedIcon />
            </MenuItem>

            <Modal open={isOpen} onClose={() => {
                setIsOpen(false)
                onReportClick()
            }}>
                <ModalDialog onClick={handleModalContentClick} ref={modalRef}>
                    <Typography level="h4">Submit a report</Typography>
                    <Typography mb={2} sx={{ fontSize: '0.875rem' }}>
                        Thanks for looking out for the community by reporting
                        things that break the rules. Let us know what's going
                        on, and we'll look into it.
                    </Typography>
                    <Box mb={2}>
                        <Stack
                            direction="row"
                            spacing={1}
                            useFlexGap
                            flexWrap="wrap"
                        >
                            {REPORT_REASONS.map((reason) => (
                                <Chip
                                    key={reason}
                                    onClick={() => handleReasonSelect(reason)}
                                    variant={
                                        selectedReason === reason
                                            ? 'solid'
                                            : 'soft'
                                    }
                                    color={
                                        selectedReason === reason
                                            ? 'primary'
                                            : 'neutral'
                                    }
                                >
                                    {reason}
                                </Chip>
                            ))}
                        </Stack>
                    </Box>
                    <Textarea
                        placeholder="Tell us more"
                        minRows={3}
                        maxRows={6}
                        value={description}
                        onChange={(e) =>
                            setDescription(e.target.value.slice(0, 500))
                        }
                        sx={{ mb: 2 }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => {
                                setIsOpen(false)
                                onReportClick()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            disabled={!selectedReason}
                        >
                            Submit
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>

            <Modal
                open={isConfirmationModalOpen}
                onClose={() => {
                    setIsConfirmationModalOpen(false)
                    onReportClick()
                }}
            >
                <ModalDialog onClick={handleModalContentClick} ref={modalRef}>
                    <Typography level="h4" mb={2}>
                        Thanks for submitting your report, we're on it!
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => {
                                setIsConfirmationModalOpen(false)
                                onReportClick()
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </>
    )
}

export default Report
