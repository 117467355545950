import React, { useEffect, useState } from 'react'
import { useUser } from 'util/UserContext'
import { Box, Typography, Grid, CircularProgress } from '@mui/joy'
import api from 'config/axiosConfig'
import EnemyCard from 'components/Enemies/EnemyCard'
import ImpactCard from 'components/Impacts/ImpactCard'
import SolutionCard from 'components/Solutions/SolutionCard'
import { User, Entity } from 'util/constants'

const UserProfileEntities = () => {
    const { currentUser } = useUser()
    const [entities, setEntities] = useState<Entity[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)
    const [page, setPage] = useState<number>(1)
    const [hasMore, setHasMore] = useState<boolean>(true)

    const fetchUserEntities = async (currentUser: User, page: number) => {
        try {
            const response = await api.get<Entity[]>('/api/entities', {
                params: {
                    username: currentUser?.username,
                    limit: 5,
                    offset: (page - 1) * 5,
                },
            })

            if (response.data.length < 5) {
                setHasMore(false)
            }

            setEntities((prevEntities) => {
                const newEntities = response.data.filter(
                    (newEntity) =>
                        !prevEntities.some(
                            (entity) => entity.eid === newEntity.eid
                        )
                )
                return [...prevEntities, ...newEntities]
            })
            setLoading(false)
        } catch (error) {
            setError('Failed to fetch entities')
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUserEntities(currentUser!, page)
    }, [currentUser, page])

    useEffect(() => {
        const handleScroll = () => {
            if (loading || !hasMore) return

            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100
            ) {
                setPage((prevPage) => prevPage + 1)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [loading, hasMore])

    const renderEntityCard = (entity: Entity) => {
        const { entity_type, attributes, eid } = entity

        switch (entity_type) {
            case 'enemy':
                return (
                    <EnemyCard
                        key={eid}
                        enemy={attributes}
                        showMeta={true}
                        showActions={true}
                        truncateDescription={true}
                        isEnemyProfilePage={false}
                    />
                )
            case 'impact':
                return (
                    <ImpactCard
                        key={eid}
                        impact={attributes}
                        showMeta={true}
                        showActions={true}
                        truncateDescription={true}
                        isImpactProfilePage={false}
                    />
                )
            case 'solution':
                return (
                    <SolutionCard
                        key={eid}
                        solution={attributes}
                        showMeta={true}
                        showActions={true}
                        truncateDescription={true}
                        isSolutionProfilePage={false}
                    />
                )
            default:
                return null
        }
    }

    if (loading && page === 1) {
        return <CircularProgress />
    }

    if (error) {
        return <Typography color="danger">{error}</Typography>
    }

    return (
        <Box sx={{ my: 2 }}>
            {/* Sort & Filter Bar - currently just sort by 'New' */}
            <Box sx={{ mb: 2 }}>
                <Typography>Sort by: New</Typography>
            </Box>

            <Grid container spacing={1}>
                {entities.map((entity) => (
                    <Grid xs={12} key={entity.eid}>
                        {renderEntityCard(entity)}
                    </Grid>
                ))}
            </Grid>

            {loading && <CircularProgress />}
        </Box>
    )
}

export default UserProfileEntities
