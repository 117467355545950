import React, { useEffect, useState } from 'react'
import { useUser } from 'util/UserContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import UserSettingsAccount from './UserSettingsAccount'
import UserSettingsProfile from './UserSettingsProfile'
import { Tabs, Tab, TabList, TabPanel, Container } from '@mui/joy'

const UserSettings = () => {
    const { currentUser, awaitingUser } = useUser();
    const navigate = useNavigate()
    const { tab } = useParams<{ tab: string }>()
    const [selectedTab, setSelectedTab] = useState<string>(tab || 'account')

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true })
        }
    }, [currentUser, awaitingUser, navigate])

    const handleTabChange = (
        event: React.SyntheticEvent | null,
        newValue: string | number | null
    ) => {
        if (typeof newValue === 'string') {
            setSelectedTab(newValue)
        }
    }

    return (
        <Container>
            <Tabs
                value={selectedTab}
                defaultValue={tab || 'account'}
                onChange={handleTabChange}
            >
                <TabList>
                    <Tab
                        component={Link}
                        to={`/settings/account`}
                        value="account"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration:
                                selectedTab === 'account'
                                    ? 'underline'
                                    : 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                                backgroundColor: 'neutral.200',
                            },
                        }}
                    >
                        Account
                    </Tab>
                    <Tab
                        component={Link}
                        to={`/settings/profile`}
                        value="profile"
                        disableIndicator={true}
                        sx={{
                            borderRadius: '12px',
                            textDecoration:
                                selectedTab === 'profile'
                                    ? 'underline'
                                    : 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                                backgroundColor: 'neutral.200',
                            },
                        }}
                    >
                        Profile
                    </Tab>
                </TabList>
                <TabPanel value="account">
                    <UserSettingsAccount />
                </TabPanel>
                <TabPanel value="profile">
                    <UserSettingsProfile />
                </TabPanel>
            </Tabs>
        </Container>
    )
}

export default UserSettings
