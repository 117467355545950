// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-content {
    @media (min-width: 768px) {
        width: calc(100% - 9rem);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Entities/EntityCard.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".card-content {\r\n    @media screen(md) {\r\n        width: calc(100% - 9rem);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
