// UserContext.tsx

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import api from 'config/axiosConfig';
import { User } from 'util/constants';

interface UserContextProps {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  awaitingUser: boolean;
  showAuthModal: boolean;
  setShowAuthModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [awaitingUser, setAwaitingUser] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get('/api/user');
        setCurrentUser(response.data);
        setShowAuthModal(false); // Close the auth modal if user is authenticated
      } catch (error) {
        setCurrentUser(null);
      } finally {
        setAwaitingUser(false);
      }
    };

    fetchUser();
  }, []);

  // Close the auth modal whenever currentUser changes to a non-null value
  useEffect(() => {
    if (currentUser) {
      setAwaitingUser(false);
      setShowAuthModal(false);
    }
  }, [currentUser]);

  return (
    <UserContext.Provider
      value={{ currentUser, setCurrentUser, awaitingUser, showAuthModal, setShowAuthModal }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
