import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../util/UserContext';

const AuthLogin = () => {
  const navigate = useNavigate();
  const { currentUser, awaitingUser, setShowAuthModal } = useUser();

  useEffect(() => {
    if (!awaitingUser) {
      if (currentUser) {
        // User is authenticated, navigate to home page
        navigate('/', { replace: true });
      } else {
        // User is not authenticated, show the auth modal
        setShowAuthModal(true);
      }
    }
  }, [currentUser, awaitingUser, navigate, setShowAuthModal]);

  // No need to render anything; the auth modal is handled via UserContext and App
  return null;
};

export default AuthLogin;