import React, { useState, useEffect } from 'react'
import api from 'config/axiosConfig'
import axios from 'axios'
import AuthModal from 'components/Auth/AuthModal'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import './Save.css'

interface Props {
    entityId: string
    entityType: string
    currentUser: { id: number } | null
    error?: string
    onSave?: () => void
}

const Save = ({ entityId, entityType, currentUser, onSave }: Props) => {
    const [isSaved, setIsSaved] = useState<boolean>(false)
    const [showAuthModal, setShowAuthModal] = useState<boolean>(false)
    const [showTransitionIcon, setShowTransitionIcon] = useState<boolean>(false)
    const [isAdding, setIsAdding] = useState<boolean>(false)

    useEffect(() => {
        const fetchSaveStatus = async () => {
            if (currentUser) {
                try {
                    const response = await api.get(
                        `/api/saves/status/${entityType}/${entityId}`
                    )
                    setIsSaved(response.data.isSaved)
                } catch (error) {
                    console.error('Error fetching save status:', error)
                }
            }
        }

        fetchSaveStatus()
    }, [entityId, entityType, currentUser])

    const handleSave = async () => {
        if (!currentUser) {
            setShowAuthModal(true)
            return
        }

        try {
            setShowTransitionIcon(true)
            setIsAdding(!isSaved)

            if (isSaved) {
                await api.delete(`/api/saves/${entityType}/${entityId}`)
            } else {
                await api.post(`/api/saves`, {
                    entityType,
                    entityId,
                })
            }

            setIsSaved(!isSaved)
            if (onSave) onSave()

            setTimeout(() => {
                setShowTransitionIcon(false)
            }, 500)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 401) {
                    setShowAuthModal(true)
                } else {
                    console.error('Error saving/unsaving:', error.message)
                }
            } else {
                console.error('An unexpected error occurred:', error)
            }
            setShowTransitionIcon(false)
        }
    }

    return (
        <div className="save-button size-9 rounded-3xl bg-gray-200 flex justify-center items-center hover:bg-gray-300 transition-colors">
            <button
                className={isSaved ? 'active' : 'inactive'}
                onClick={handleSave}
            >
                {showTransitionIcon ? (
                    isAdding ? <BookmarkAddedIcon className="transition-icon" /> : <BookmarkRemoveIcon className="transition-icon" />
                ) : (
                    isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />
                )}
            </button>
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    onClose={() => setShowAuthModal(false)}
                />
            )}
        </div>
    )
}

export default Save
