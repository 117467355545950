import React from 'react'
import { useParams } from 'react-router-dom'
import EnemiesList from './Enemies/EnemiesList'
import './MainPage.css'
import { Sort } from 'util/constants'

const MainPage = () => {
    const { sort } = useParams<{ sort: Sort }>()

    return (
        <div className="main-page container mx-auto md:px-4">
            <EnemiesList sort={sort} />
        </div>
    )
}

export default MainPage
