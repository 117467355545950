import React, { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import { useParams, useSearchParams } from 'react-router-dom'
import EnemyCard from 'components/Enemies/EnemyCard'
import SolutionCard from './SolutionCard'
import AddEnemyToSolution from 'components/Enemies/AddEnemyToSolution'
import CommentsSection from 'components/Comments/CommentsSection'
import SolutionEditor from './SolutionEditor'
import { CircularProgress } from '@mui/joy'

import { Solution, Enemy } from 'util/constants'

interface RelatedEnemy extends Enemy {
    enemy_id: number
}

const SolutionProfile = () => {
    const { id } = useParams<{ id: string }>()
    const [solution, setSolution] = useState<Solution | null>(null)
    const [relatedEnemies, setRelatedEnemies] = useState<RelatedEnemy[]>([])
    const [error, setError] = useState<string>('')
    const [editMode, setEditMode] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [referenceLink, setReferenceLink] = useState<string>('')
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const fetchSolution = async () => {
            try {
                const response = await api.get<Solution>(
                    `/api/solutions/id/${id}`
                )
                setSolution(response.data)
                setDescription(response.data.description)
                setReferenceLink(response.data.reference_link)
                if (searchParams.get('edit') === 'true') {
                    setEditMode(true)
                }
            } catch (err) {
                setError(
                    'Failed to load solution profile. Please try again later.'
                )
            }
        }

        const fetchRelatedEnemies = async () => {
            try {
                const response = await api.get<RelatedEnemy[]>(
                    `/api/solutions/${id}/enemies`
                )
                const enemyIds = response.data.map((enemy) => enemy.enemy_id)
                const enemiesResponse = await api.get<RelatedEnemy[]>(
                    `/api/enemies?enemyIds=${JSON.stringify(enemyIds)}`
                )
                setRelatedEnemies(enemiesResponse.data)
            } catch (err) {
                setError(
                    'Failed to load related enemies. Please try again later.'
                )
            }
        }

        fetchSolution()
        fetchRelatedEnemies()
    }, [id, searchParams])

    const handleEnemyAdded = (newEnemy: RelatedEnemy) => {
        setRelatedEnemies((prevEnemies) => [...prevEnemies, newEnemy])
    }

    if (error) {
        return <div>{error}</div>
    }

    if (!solution) {
        return (
            <div>
                <CircularProgress size="lg" />
            </div>
        )
    }

    return (
        <div className="solution-profile">
            {solution && (
                <>
                    {editMode ? (
                        <SolutionEditor
                            solution={solution}
                            description={description}
                            referenceLink={referenceLink}
                            editMode={true}
                        />
                    ) : (
                        <SolutionCard
                            solution={solution}
                            isSolutionProfilePage={true}
                            truncateDescription={true}
                        />
                    )}
                </>
            )}
            <CommentsSection
                commentableId={solution.id}
                commentableType="solution"
            />
            <div className="related-enemies-container">
                <h2>Related Enemies</h2>
                <AddEnemyToSolution
                    solutionId={id!}
                    onEnemyAdded={handleEnemyAdded}
                />
                {relatedEnemies.map((enemy) => (
                    <EnemyCard
                        key={enemy.id}
                        enemy={enemy}
                        showMeta={true}
                        showActions={true}
                        onVote={() => {}}
                        truncateDescription={true}
                    />
                ))}
            </div>
        </div>
    )
}

export default SolutionProfile
