import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { useNavigate } from 'react-router-dom'
import { Typography, Container, Stack, FormControl, FormLabel } from '@mui/joy'
import UsernameModal from 'components/User/UsernameModal'
import ArrowForward from '@mui/icons-material/ArrowForward'

const UserSettingsProfile = () => {
    const { currentUser, awaitingUser } = useUser()
    const navigate = useNavigate()
    const [showUsernameModal, setShowUsernameModal] = useState<boolean>(false)

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true })
        }
    }, [currentUser, awaitingUser, navigate])

    return (
        <Container>
            <Stack direction="column" spacing={2}>
                <FormControl>
                    <FormLabel>General</FormLabel>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        onClick={() => setShowUsernameModal(true)}
                        sx={{ cursor: 'pointer' }}
                    >
                        <Typography>Username</Typography>
                        <ArrowForward />
                    </Stack>
                </FormControl>
            </Stack>
            {showUsernameModal && (
                <UsernameModal
                    onClose={() => setShowUsernameModal(false)}
                />
            )}
        </Container>
    )
}

export default UserSettingsProfile
