// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.auth-modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    text-align: center;
}

.auth-modal .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.auth-button {
    display: block;
    margin: 10px 0;
    padding: 10px;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    color: white;
}

.google {
    background: #db4437;
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".auth-modal {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n}\n\n.auth-modal-content {\n    background: white;\n    padding: 20px;\n    border-radius: 5px;\n    position: relative;\n    text-align: center;\n}\n\n.auth-modal .close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.auth-button {\n    display: block;\n    margin: 10px 0;\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n    text-align: center;\n    text-decoration: none;\n    font-size: 16px;\n    color: white;\n}\n\n.google {\n    background: #db4437;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
