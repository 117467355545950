import React, { forwardRef, Ref } from 'react';
import Textarea from '@mui/joy/Textarea';
import { SubmitHandler, useForm } from 'react-hook-form';
import Box from '@mui/joy/Box';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
interface CommentInputProps {
  onSubmit: (content: string) => void;
  onCancel?: () => void;
  buttonText: string;
  initialContent?: string;
}

interface FormInputs {
  content: string;
}

const CommentInput = forwardRef<HTMLDivElement, CommentInputProps>(({
  onSubmit,
  onCancel,
  buttonText,
  initialContent = ''
}, ref) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: { content: initialContent },
  });

  const onSubmitForm: SubmitHandler<FormInputs> = (data: any| null | undefined) => {
    onSubmit(data?.content);
    reset({ content: '' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm ?? '')} >
      <div className="mb-4 mt-2 flex flex-col gap-2">
        <Textarea
          {...register('content', {
            required: 'Content is required',
            minLength: { value: 1, message: 'Content must be at least 1 character long' },
            maxLength: { value: 6000, message: 'Content must not exceed 1000 characters' },
          })}
          minRows={3}
          maxRows={60}
          sx={{
            borderRadius: '20px'
          }}
          slotProps={{
            textarea: {
              ref: ref as Ref<HTMLTextAreaElement>,
            }
          }}
          endDecorator={<Box
            sx={{
              display: 'flex',
              borderColor: 'divider',
              flex: 'auto',
              justifyContent: 'flex-end'
            }}>
            {onCancel && (
              <button
                type="button"
                onClick={onCancel}
                className="rounded-3xl px-4 py-3 mx-2 flex bg-gray-200 justify-content-end items-center gap-2 text-sm font-semibold hover:bg-gray-400 hover:text-white cursor-pointer"
              >
                Cancel
              </button>
            )}
              <button
                type="submit"
                className="rounded-3xl px-4 py-3 mx-2 flex bg-gray-200 justify-content-end items-center gap-1 text-sm font-semibold hover:bg-blue-700 hover:text-white cursor-pointer"                
              >
                <span>
                  <SendOutlinedIcon  className="text-xl -rotate-45"/>
                </span> 
                {buttonText}
              </button>
            </Box>
          }
        />
        {errors.content && (
          <p className="mt-1 text-sm text-red-500">{errors.content.message}</p>
        )}
      </div>
    </form>
  );
});

export default CommentInput;