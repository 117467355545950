import { useEffect } from 'react'

const GoogleSignin = ({ handleCredentialResponse }) => {
    useEffect(() => {
        const loadGoogleSignin = () => {
            const script = document.createElement('script')
            script.src = 'https://accounts.google.com/gsi/client'
            script.async = true
            script.defer = true
            document.body.appendChild(script)

            script.onload = () => {
                window.google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    callback: handleCredentialResponse,
                })
                window.google.accounts.id.renderButton(
                    document.getElementById('google-signin-button'),
                    { theme: 'outline', size: 'large' }
                )
            }
        }

        loadGoogleSignin()
    }, [handleCredentialResponse])

    return <div id="google-signin-button"></div>
}

export default GoogleSignin
