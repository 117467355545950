import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import { useNavigate } from 'react-router-dom'
import {
    Textarea,
    Button,
    FormLabel,
    FormControl,
    Select,
    Option,
    CircularProgress,
} from '@mui/joy'

import { Impact, Scope } from 'util/constants'

interface Props {
    impact: Impact
    description: string
    scope: Scope | null
    referenceLink: string
    editMode?: boolean
}

const ImpactEditor = ({
    impact,
    description,
    scope,
    referenceLink,
    editMode = true,
}: Props) => {
    const { currentUser, setShowAuthModal } = useUser()
    const navigate = useNavigate()
    const [newDescription, setDescription] = useState<string>(description)
    const [newScope, setScope] = useState<Scope>(scope as Scope)
    const [authorized, setAuthorized] = useState<boolean>(true)

    useEffect(() => {
        if (!currentUser) {
            setAuthorized(false)
            setShowAuthModal(true)
        } else if (currentUser.id !== impact.user_id) {
            setAuthorized(false)
            navigate(`/impact/${impact.id}`)
            window.location.reload()
        }
    }, [currentUser, setShowAuthModal, impact, navigate])

    if (!authorized) {
        return null // Prevent rendering if not authorized
    }

    const handleScopeChange = (
        _event: React.SyntheticEvent | null,
        newValue: Scope | null
    ) => {
        if (newValue) setScope(newValue)
    }

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescription(e.target.value)
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        try {
            await api.patch(`/api/impacts/id/${impact.id}`, {
                description: newDescription,
                scope: newScope,
                reference_link: referenceLink,
            })
            navigate(`/impact/${impact.id}`)
            window.location.reload()
        } catch (error) {
            console.error('Error saving impact:', error)
        }
    }

    const handleCancel = () => {
        navigate(`/impact/${impact.id}`)
        window.location.reload()
    }

    if (!impact) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="card-margin">
            <div>
                {impact && (
                    <div className="edit-impact-card">
                        <h3 className="impact-title">{impact.title}</h3>
                        <FormLabel id="input-scope-select-label">
                            Scope
                        </FormLabel>
                        <Select
                            id="scope-select"
                            value={newScope}
                            onChange={handleScopeChange}
                        >
                            <Option value="Local">Local</Option>
                            <Option value="National">National</Option>
                            <Option value="Global">Global</Option>
                        </Select>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                minRows={8}
                                maxRows={1024}
                                value={newDescription}
                                onChange={handleDescriptionChange}
                            />
                        </FormControl>
                        <div className="edit-actions">
                            <Button
                                variant="outlined"
                                color="neutral"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="solid"
                                color="primary"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </div>
                        {impact.reference_link && (
                            <a
                                href={impact.reference_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="impact-card-link"
                            >
                                {impact.reference_link}
                            </a>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ImpactEditor
