// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vote-container button:hover svg path {
    stroke: #cf1f1f;
}

.vote-container button.active {
    color: #cf1f1f;
}

.vote-container button.active svg path {
    fill: #cf1f1f;
    stroke: #cf1f1f;
}

.vote-container button.downvote:hover svg path {
    stroke: #1e40af;
}

.vote-container button.downvote.active {
    color: #1e40af;
}

.vote-container button.downvote.active svg path {
    fill: #1e40af;
    stroke: #1e40af;
}
`, "",{"version":3,"sources":["webpack://./src/components/Entities/Vote.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".vote-container button:hover svg path {\r\n    stroke: #cf1f1f;\r\n}\r\n\r\n.vote-container button.active {\r\n    color: #cf1f1f;\r\n}\r\n\r\n.vote-container button.active svg path {\r\n    fill: #cf1f1f;\r\n    stroke: #cf1f1f;\r\n}\r\n\r\n.vote-container button.downvote:hover svg path {\r\n    stroke: #1e40af;\r\n}\r\n\r\n.vote-container button.downvote.active {\r\n    color: #1e40af;\r\n}\r\n\r\n.vote-container button.downvote.active svg path {\r\n    fill: #1e40af;\r\n    stroke: #1e40af;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
