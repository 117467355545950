// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-button button.active svg {
    fill: #555555;
}

.save-button button.inactive svg {
    fill: #555555;
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: .2; }
}`, "",{"version":3,"sources":["webpack://./src/components/Entities/Save.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,KAAK,UAAU,EAAE;IACjB,OAAO,WAAW,EAAE;AACxB","sourcesContent":[".save-button button.active svg {\r\n    fill: #555555;\r\n}\r\n\r\n.save-button button.inactive svg {\r\n    fill: #555555;\r\n}\r\n\r\n@keyframes fadeOut {\r\n    0% { opacity: 1; }\r\n    100% { opacity: .2; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
