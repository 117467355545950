// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .action.comment:hover svg path {
    fill: #555555;
}

.action.save button {
    border: none;
    color: none;
    background: none;
    cursor: pointer;
}

.action.save button.inactive svg path {
    fill: none;
}

.action.save button.active svg path {
    fill: #555555;
}

.vote-count {
    font-size: 24px;
    font-weight: bold;
    color: #555555;
} */

/* 
.option {
    cursor: pointer;
}

.option svg path {
    stroke: #555555;
    fill: #555555;
}

.copied-banner {
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: #ff0000;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 0;
    z-index: 1000;
    animation: fadeOut 2s forwards;
}

.copied-banner p {
    margin: 0;
    margin-left: 50%;
    font-size: 16px;
    font-weight: bold;
}*/

.copied-banner {
    right: auto;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    background-color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    animation: fadeOut 2s forwards;
    animation-delay: 1s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Entities/EntityActions.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;GAuBG;;AAEH;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EA6BE;;AAEF;IACI,WAAW;IACX,MAAM;IACN,2BAA2B;IAC3B,SAAS;IACT,uBAAuB;IACvB,eAAe;IACf,qBAAqB;IACrB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":["/* .action.comment:hover svg path {\r\n    fill: #555555;\r\n}\r\n\r\n.action.save button {\r\n    border: none;\r\n    color: none;\r\n    background: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.action.save button.inactive svg path {\r\n    fill: none;\r\n}\r\n\r\n.action.save button.active svg path {\r\n    fill: #555555;\r\n}\r\n\r\n.vote-count {\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n    color: #555555;\r\n} */\r\n\r\n/* \r\n.option {\r\n    cursor: pointer;\r\n}\r\n\r\n.option svg path {\r\n    stroke: #555555;\r\n    fill: #555555;\r\n}\r\n\r\n.copied-banner {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 50%;\r\n    width: 100%;\r\n    transform: translateX(-50%);\r\n    background-color: #ff0000;\r\n    color: #ffffff;\r\n    padding: 10px 20px;\r\n    border-radius: 0;\r\n    z-index: 1000;\r\n    animation: fadeOut 2s forwards;\r\n}\r\n\r\n.copied-banner p {\r\n    margin: 0;\r\n    margin-left: 50%;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n}*/\r\n\r\n.copied-banner {\r\n    right: auto;\r\n    top: 0;\r\n    transform: translateX(-50%);\r\n    left: 50%;\r\n    background-color: white;\r\n    padding: 0.5rem;\r\n    border-radius: 0.5rem;\r\n    animation: fadeOut 2s forwards;\r\n    animation-delay: 1s;\r\n}\r\n\r\n@keyframes fadeOut {\r\n    0% {\r\n        opacity: 1;\r\n    }\r\n    100% {\r\n        opacity: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
