import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { Tabs, Tab, TabList, TabPanel, Box, Sheet, Container } from '@mui/joy'
import { useParams, Link } from 'react-router-dom'
import api from 'config/axiosConfig'
import UserProfileOverview from './UserProfileOverview'
import UserProfileEntities from './UserProfileEntities'
import UserProfileComments from './UserProfileComments'
import UserProfileSaved from './UserProfileSaved'
import { User } from 'util/constants'

const UserProfile = () => {
    const { currentUser } = useUser()
    const { username, tab } = useParams<{ username: string; tab?: string }>()
    const [selectedTab, setSelectedTab] = useState<string>(tab || 'overview')
    const [usernameActive, setUsernameActive] = useState<User | null>(null)
    const [error, setError] = useState<string>('')

    useEffect(() => {
        const fetchUsername = async () => {
            try {
                const response = await api.get<User>(`/api/user/${username}`)
                setUsernameActive(response.data)
            } catch (error) {
                console.error(
                    `Error fetching user with username: ${username}`,
                    error
                )
                setError(`User with username ${username} does not exist.`)
            }
        }

        fetchUsername()
    }, [username])

    const handleTabChange = (
        event: React.SyntheticEvent | null,
        newValue: string | number | null
    ) => {
        if (typeof newValue === 'string') {
            setSelectedTab(newValue)
            if (newValue === 'overview') {
                window.location.href = `/user/${username}`
            } else {
                window.location.href = `/user/${username}/${newValue}`
            }
        }
    }

    if (!usernameActive) {
        return <Sheet>{error}</Sheet>
    }

    return (
        <Container>
            <Box>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="User Profile Tabs"
                    size="lg"
                    sx={{
                        '--Tabs-gap': '8px',
                        '--Tabs-radius': '12px',
                        '--Tabs-color': 'neutral.outlinedActiveBg',
                        marginTop: '6rem',
                    }}
                >
                    <TabList
                        sticky="top"
                        sx={{
                            backgroundColor: 'background.surface',
                            borderRadius: '8px',
                            p: 1,
                        }}
                    >
                        <Tab
                            component={Link}
                            to={`/user/${username}`}
                            value="overview"
                            disableIndicator={true}
                            sx={{
                                borderRadius: '12px',
                                textDecoration:
                                    selectedTab === 'overview'
                                        ? 'underline'
                                        : 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'neutral.200',
                                },
                            }}
                        >
                            Overview
                        </Tab>
                        <Tab
                            component={Link}
                            to={`/user/${username}/entities`}
                            value="entities"
                            disableIndicator={true}
                            sx={{
                                borderRadius: '12px',
                                textDecoration:
                                    selectedTab === 'entities'
                                        ? 'underline'
                                        : 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'neutral.200',
                                },
                            }}
                        >
                            Entities
                        </Tab>
                        <Tab
                            component={Link}
                            to={`/user/${username}/comments`}
                            value="comments"
                            disableIndicator={true}
                            sx={{
                                borderRadius: '12px',
                                textDecoration:
                                    selectedTab === 'comments'
                                        ? 'underline'
                                        : 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'neutral.200',
                                },
                            }}
                        >
                            Comments
                        </Tab>
                        {username === currentUser?.username && (
                            <Tab
                                component={Link}
                                to={`/user/${username}/saved`}
                                value="saved"
                                disableIndicator={true}
                                sx={{
                                    borderRadius: '12px',
                                    textDecoration:
                                        selectedTab === 'saved'
                                            ? 'underline'
                                            : 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                        backgroundColor: 'neutral.200',
                                    },
                                }}
                            >
                                Saved
                            </Tab>
                        )}
                    </TabList>
                    <TabPanel value="overview">
                        <UserProfileOverview />
                    </TabPanel>
                    <TabPanel value="entities">
                        <UserProfileEntities />
                    </TabPanel>
                    <TabPanel value="comments">
                        <UserProfileComments />
                    </TabPanel>
                    <TabPanel value="saved">
                        <UserProfileSaved />
                    </TabPanel>
                </Tabs>
            </Box>
        </Container>
    )
}

export default UserProfile
