import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { Navigate } from 'react-router-dom'
import {
    Table,
    Sheet,
    Select,
    Option,
    Button,
    Textarea,
    Typography,
    IconButton,
} from '@mui/joy'
import {
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Collapse,
    Box,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import api from 'config/axiosConfig'

interface AdminReportsProps {
    isAdmin: boolean
}

interface Report {
    id: string
    user_id: string
    entity_type: string
    entity_id: string
    reason: string
    description: string | null
    status: string
    decision_description: string | null
    created_ts: string
    updated_ts: string
}

interface RowProps {
    report: Report
    handleStatusChange: (reportId: string, newStatus: string) => void
    handleDecisionDescriptionChange: (
        reportId: string,
        newDescription: string
    ) => void
    handleSave: (reportId: string) => void
}

const REPORT_STATUSES = [
    'awaiting_review',
    'in_review',
    'report_valid',
    'report_dismissed',
]

const Row = ({
    report,
    handleStatusChange,
    handleDecisionDescriptionChange,
    handleSave,
}: RowProps) => {
    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{report.id}</TableCell>
                <TableCell>{report.user_id}</TableCell>
                <TableCell>{report.entity_type}</TableCell>
                <TableCell>{report.entity_id}</TableCell>
                <TableCell>{report.reason}</TableCell>
                <TableCell>
                    <Select
                        value={report.status}
                        onChange={(_, value) =>
                            handleStatusChange(report.id, value as string)
                        }
                    >
                        {REPORT_STATUSES.map((status) => (
                            <Option key={status} value={status}>
                                {status}
                            </Option>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                    {new Date(report.created_ts).toLocaleString()}
                </TableCell>
                <TableCell>
                    {new Date(report.updated_ts).toLocaleString()}
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="lg"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleSave(report.id)}>Save</Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                gutterBottom
                                component="div"
                                sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                            >
                                Details
                            </Typography>
                            <Table size="sm" aria-label="details">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Description
                                        </TableCell>
                                        <TableCell>
                                            {report.description}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Decision Description
                                        </TableCell>
                                        <TableCell>
                                            <Textarea
                                                value={
                                                    report.decision_description ??
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    handleDecisionDescriptionChange(
                                                        report.id,
                                                        e.target.value
                                                    )
                                                }
                                                disabled={
                                                    report.status !==
                                                    'in_review'
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const AdminReports = ({ isAdmin }: AdminReportsProps) => {
    const { currentUser, awaitingUser } = useUser()
    const [reports, setReports] = useState<Report[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        if (!awaitingUser && isAdmin) {
            fetchReports()
        }
    }, [awaitingUser, isAdmin])

    const fetchReports = async () => {
        try {
            const response = await api.get('/api/reports')
            setReports(response.data)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching reports:', error)
            setError('Failed to fetch reports')
            setLoading(false)
        }
    }

    const handleStatusChange = (reportId: string, newStatus: string) => {
        setReports(
            reports.map((report) =>
                report.id === reportId
                    ? { ...report, status: newStatus }
                    : report
            )
        )
    }

    const handleDecisionDescriptionChange = (
        reportId: string,
        newDescription: string
    ) => {
        setReports(
            reports.map((report) =>
                report.id === reportId
                    ? { ...report, decision_description: newDescription }
                    : report
            )
        )
    }

    const handleSave = async (reportId: string) => {
        const report = reports.find((r) => r.id === reportId)
        if (!report) return

        try {
            await api.patch(`/api/reports/${reportId}`, {
                status: report.status,
                decisionDescription: report.decision_description,
            })
            // Optionally, you can fetch the reports again to ensure the data is up-to-date
            await fetchReports()
        } catch (error) {
            console.error('Error updating report:', error)
            setError('Failed to update report')
        }
    }

    if (awaitingUser) {
        return <Sheet>Loading...</Sheet>
    }

    if (!currentUser || !currentUser.attributes?.is_admin) {
        return <Navigate to="/" />
    }

    if (loading) return <Typography>Loading...</Typography>

    if (error) return <Typography color="danger">{error}</Typography>

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>User ID</TableCell>
                        <TableCell>Entity Type</TableCell>
                        <TableCell>Entity ID</TableCell>
                        <TableCell>Reason</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell>Descriptions</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reports.map((report) => (
                        <Row
                            key={report.id}
                            report={report}
                            handleStatusChange={handleStatusChange}
                            handleDecisionDescriptionChange={
                                handleDecisionDescriptionChange
                            }
                            handleSave={handleSave}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AdminReports
