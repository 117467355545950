import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CommentsSection from 'components/Comments/CommentsSection'
import SolutionsSection from 'components/Solutions/SolutionsSection'
import ImpactsSection from 'components/Impacts/ImpactsSection'
import { Box, Tabs, Tab, TabList, TabPanel, Container } from '@mui/joy'
import { Enemy } from 'util/constants'

interface Props {
    enemy: Enemy
}

const EnemyProfileTabs = ({ enemy }: Props) => {
    const { name, tab } = useParams()
    const [selectedTab, setSelectedTab] = useState(tab || 'comments')

    return (
        <Box>
            <Container>
                <Tabs
                    value={selectedTab}
                    onChange={(e, newValue) => {
                        setSelectedTab(`${newValue}`)
                    }}
                    aria-label="Enemy Profile Tabs"
                    size="lg"
                    sx={{
                        '--Tabs-gap': '8px',
                        '--Tabs-radius': '12px',
                        '--Tabs-color': 'neutral.outlinedActiveBg',
                    }}
                >
                    <TabList
                        sticky="top"
                        sx={{
                            backgroundColor: 'background.surface',
                            borderRadius: '8px',
                            p: 1,
                        }}
                    >
                        <Tab
                            component={Link}
                            to={`/enemy/${name}/comments`}
                            value="comments"
                            disableIndicator={true}
                            sx={{
                                borderRadius: '12px',
                                textDecoration:
                                    selectedTab === 'comments'
                                        ? 'underline'
                                        : 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'neutral.200',
                                },
                            }}
                        >
                            Comments
                        </Tab>
                        <Tab
                            component={Link}
                            to={`/enemy/${name}/impacts`}
                            value="impacts"
                            disableIndicator={true}
                            sx={{
                                borderRadius: '12px',
                                textDecoration:
                                    selectedTab === 'impacts'
                                        ? 'underline'
                                        : 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'neutral.200',
                                },
                            }}
                        >
                            Impacts
                        </Tab>
                        <Tab
                            component={Link}
                            to={`/enemy/${name}/solutions`}
                            value="solutions"
                            disableIndicator={true}
                            sx={{
                                borderRadius: '12px',
                                textDecoration:
                                    selectedTab === 'solutions'
                                        ? 'underline'
                                        : 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    backgroundColor: 'neutral.200',
                                },
                            }}
                        >
                            Solutions
                        </Tab>
                    </TabList>
                    <TabPanel value="comments">
                        <CommentsSection
                            commentableId={enemy.id}
                            commentableType="enemy"
                        />
                    </TabPanel>
                    <TabPanel value="impacts">
                        <ImpactsSection
                            enemy={enemy}
                        />
                    </TabPanel>
                    <TabPanel value="solutions">
                        <SolutionsSection
                            enemy={enemy}
                        />
                    </TabPanel>
                </Tabs>
            </Container>
        </Box>
    )
}

export default EnemyProfileTabs