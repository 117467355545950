import React, { useState } from 'react'
import {
    Modal,
    ModalDialog,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    FormHelperText,
} from '@mui/joy'
import api from 'config/axiosConfig'
import AuthModal from 'components/Auth/AuthModal'
import { User } from 'util/constants'

interface Props {
    enemyId: number
    onSolutionAdded: (solution: Solution) => void
    currentUser: User | null
    open: boolean
    onClose: () => void
}

interface Solution {
    id: number
    name: string
    description: string
    reference_link: string
    user_id: number
}

const CreateSolution = ({
    enemyId,
    onSolutionAdded,
    currentUser,
    open,
    onClose,
}: Props) => {
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [referenceLink, setReferenceLink] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [showAuthModal, setShowAuthModal] = useState<boolean>(false)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!currentUser) {
            setShowAuthModal(true)
            return
        }

        try {
            const response = await api.post<Solution>(
                `/api/solutions/${enemyId}`,
                {
                    name,
                    description,
                    reference_link: referenceLink,
                    user_id: currentUser.id,
                }
            )

            const newSolution = response.data

            // Automatically upvote the solution
            await api.post(`/api/votes/solution/${newSolution.id}`, {
                user_id: currentUser.id,
                rel_type: 'user_upvote',
            })

            onSolutionAdded(newSolution)
            setName('')
            setDescription('')
            setReferenceLink('')
            setError('')
            onClose() // Close the modal after successful submission
        } catch (error) {
            console.error('Error creating solution:', error)
            setError('Failed to create solution. Please try again later.')
        }
    }

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <ModalDialog>
                    <form onSubmit={handleSubmit}>
                        <FormControl required>
                            <FormLabel>Name</FormLabel>
                            <Input
                                type="text"
                                value={name}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={description}
                                onChange={(
                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                ) => setDescription(e.target.value)}
                                minRows={5}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Reference Link</FormLabel>
                            <Input
                                type="url"
                                value={referenceLink}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setReferenceLink(e.target.value)}
                            />
                        </FormControl>
                        {error && (
                            <FormHelperText color="danger">
                                {error}
                            </FormHelperText>
                        )}
                        <Button type="submit" sx={{ mt: 2 }}>
                            Submit
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    onClose={() => setShowAuthModal(false)}
                />
            )}
        </>
    )
}

export default CreateSolution
