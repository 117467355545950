// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expand-collapse-button {
  position: absolute;
  left: 50%;
  top: 40px; /* Adjust this value to position the button below the avatar */
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/Comments/Comment.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS,EAAE,8DAA8D;EACzE,2BAA2B;EAC3B,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".expand-collapse-button {\r\n  position: absolute;\r\n  left: 50%;\r\n  top: 40px; /* Adjust this value to position the button below the avatar */\r\n  transform: translateX(-50%);\r\n  background-color: #fff;\r\n  border-radius: 50%;\r\n  padding: 4px;\r\n  z-index: 10;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
