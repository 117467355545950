import React, { useState, useEffect, useRef } from 'react';
import { useUser } from 'util/UserContext';
import toast from 'react-hot-toast';
import api from 'config/axiosConfig';
import { Comment } from 'util/constants';
import CommentInput from 'components/Comments/CommentInput';
import RenderComments from 'components/Comments/RenderComments';
import { Input, Box } from '@mui/joy';
import { ReactComponent as PlusIcon } from 'assets/icon-plus.svg'

interface Props {
  commentableType: Comment['commentableType'];
  commentableId: Comment['commentableId'];
}

const CommentsSection: React.FC<Props> = ({ commentableType, commentableId }) => {
  const { currentUser, setShowAuthModal } = useUser();
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFullInput, setShowFullInput] = useState<boolean>(false);
  const commentInputRef = useRef<HTMLDivElement>(null);
  const commentSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await api.get(`/api/comments/${commentableType}/${commentableId}`);
        const allComments = response.data;

        // Filter out only root comments that are not active
        const filteredComments = allComments.filter((comment: Comment) => {
          if (comment.parent_id === null) {
            return comment.is_active !== false;
          }
          return true;
        });

        setComments(filteredComments);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching comments:', error);
        toast.error('Failed to load comments');
        setLoading(false);
      }
    };

    fetchComments();
  }, [commentableType, commentableId]);

  const updateCommentsRecursively = (commentsArray: Comment[], updateFunction: (comment: Comment) => Comment): Comment[] => {
    return commentsArray.map(comment => {
      const updatedComment = updateFunction(comment);
      if (updatedComment.replies && updatedComment.replies.length > 0) {
        return {
          ...updatedComment,
          replies: updateCommentsRecursively(updatedComment.replies, updateFunction)
        };
      }
      return updatedComment;
    });
  };

  const addNewComment = async (content: string) => {
    try {
      const response = await api.post(`/api/comments/${commentableType}/${commentableId}`, {
        content,
      });
      let newComment = response.data;
      await api.post(`/api/votes/comment/${newComment.id}`, {
        user_id: currentUser?.id,
        rel_type: 'user_upvote',
      });
      const updatedCommentResponse = await api.get(`/api/comments/${newComment.id}`);
      newComment = {
        ...updatedCommentResponse.data,
        replies: [], // Initialize replies as an empty array
      };
      setComments([newComment, ...comments]);
      setShowFullInput(false);
      toast.success('Comment added successfully');
    } catch (error) {
      console.error('Error adding new comment:', error);
      toast.error('Failed to add comment');
    }
  };

  const addReply = async (parent_id: string, content: string) => {
    try {
      const response = await api.post(`/api/comments/${commentableType}/${commentableId}`, {
        content,
        parent_id: parent_id
      });
      let newReply = response.data;
      await api.post(`/api/votes/comment/${newReply.id}`, {
        user_id: currentUser?.id,
        rel_type: 'user_upvote',
      });
      const updatedReplyResponse = await api.get(`/api/comments/${newReply.id}`);
      newReply = {
        ...updatedReplyResponse.data,
        replies: [], // Initialize replies as an empty array
      };

      setComments(prevComments => updateCommentsRecursively(prevComments, comment => {
        if (comment.id === parent_id) {
          return {
            ...comment,
            replies: [...(comment.replies || []), newReply]
          };
        }
        return comment;
      }));
      toast.success('Reply added successfully');
    } catch (error) {
      console.error('Error adding reply:', error);
      toast.error('Failed to add reply');
    }
  };

  const updateComment = async (commentId: string, updatedContent: string) => {
    try {
      const response = await api.put(`/api/comments/${commentId}`, {
        content: updatedContent
      });
      const updatedComment = response.data;

      setComments(prevComments => updateCommentsRecursively(prevComments, comment => {
        if (comment.id === commentId) {
          return { ...comment, ...updatedComment };
        }
        return comment;
      }));
      toast.success('Comment updated successfully');
    } catch (error) {
      console.error('Error updating comment:', error);
      toast.error('Failed to update comment');
    }
  };

  const deleteComment = async (commentId: string) => {
    try {
      await api.delete(`/api/comments/${commentId}`);

      setComments(prevComments => updateCommentsRecursively(prevComments, comment => {
        if (comment.id === commentId) {
          // Replace the comment content with a placeholder
          return { ...comment, content: '[Deleted by user]', username: '[deleted]' };
        }
        return comment;
      }));
      toast.success('Comment deleted successfully');
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    }
  };
  
  const handleInputFocus = () => {
    setShowFullInput(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (commentSectionRef.current && !commentSectionRef.current.contains(event.target as Node)) {
      setShowFullInput(false);
    }
  };

  useEffect(() => {
    if (showFullInput && commentInputRef.current) {
      commentInputRef.current.focus();
    }
  }, [showFullInput]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <div>
    <div ref={commentSectionRef}>
      {!currentUser ? (
      <Box className="mb-10"
        sx={{
            flexGrow: 0,
            display: { md: 10, xs: 'block' },
        }}
      >
        <button
          className="rounded-3xl px-4 py-3 flex justify-center outline outline-2 outline-gray-300 items-center gap-2 text-sm font-semibold hover:bg-gray-200 cursor-pointer transition-colors"
            onClick={() => setShowAuthModal(true)}
        >
            <PlusIcon />
            <span>Comment</span>
          </button>
      </Box>
      ) : ( <Box className="mb-10">
        {!showFullInput ? (
          <Input
            placeholder="Add a comment"
            onClick={handleInputFocus}
            sx={{ mb: 2,
              borderRadius: '20px'
             }}
          />
        ) : (
          <CommentInput
            onSubmit={addNewComment}
            onCancel={() => setShowFullInput(false)}
            buttonText="Comment"
            ref={commentInputRef}
          />
        )}
        </Box>
    )}
    </div>
      {loading && (
        Array.from({ length: 5 }).map((_, index) => (
          <div
            className="relative mb-4 h-20 w-full animate-pulse rounded bg-gray-300 dark:bg-gray-800"
            key={index}
          ></div>
        )))}
        {comments && (
        <>
          <RenderComments
            comments={comments}
            maxDepth={30}
            isAllCollapsed={true}
            onAddReply={addReply}
            onUpdateComment={updateComment}
            onDeleteComment={deleteComment}
            commentableType={commentableType}
            commentableId={commentableId}
          />
        </>
      )}
    </div>
  );
};

export default CommentsSection;